import React from 'react';
import Slider from "react-slick";
import css from './AboutListingSection.module.css';
import demoImage from '../../assets/biketribe-brandImage-1500.jpg';
import IconCollection from '../../components/IconCollection/IconCollection';
import { FormattedMessage } from 'react-intl';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ExternalLink } from '../../components';

const AboutListingSection = (props) => {
    const { athleteDescription, shareProfile, currentListing } = props;
    const { images } = currentListing || [];
    const sliderSettings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1, // Show the second image initially
    };
    const { facebookLink, instagramLink, linkedinLink, youtubeLink, twitterLink, tiktokLink } = currentListing?.attributes?.publicData || {} 
 
    return (<div className={css.aboutContainer}>
                <div className={css.aboutContent}> 
                    <div className={css.textContent}>
                        <h2><FormattedMessage id="EditListingPage.description" /></h2>
                        <p>{athleteDescription}</p> 
                        <div className={css.socialIcons}>  
                            {facebookLink ? <ExternalLink href={facebookLink}>
                                <IconCollection name="facebook_icon" />
                            </ExternalLink> : null} 
                            {youtubeLink ? <ExternalLink href={youtubeLink}>
                                <IconCollection name="youtube_icon" />
                            </ExternalLink> : null} 
                            {instagramLink ? <ExternalLink href={instagramLink}>
                                <IconCollection name="instagram_icon" />
                            </ExternalLink> : null} 
                            {linkedinLink ? <ExternalLink href={linkedinLink}>
                                <IconCollection name="linkdin_icon" />
                            </ExternalLink> : null} 
                            {twitterLink ? <ExternalLink href={twitterLink}>
                                <IconCollection name="twitter_icon" />
                            </ExternalLink> : null} 
                            {tiktokLink ? <ExternalLink href={tiktokLink}>
                                <IconCollection name="tiktok_icon" />
                            </ExternalLink> : null}
                        </div>
                    </div> 
                    
                    <div className={css.slider}>
                        <Slider {...sliderSettings}>
                            {images?.map((image, index) => (
                                <div key={image.id.uuid || index} className={css.cardImage}>
                                    <img src={image.attributes?.variants?.['listing-card-2x']?.url || demoImage} alt={`Slide ${index + 1}`} />
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
    );
};

export default AboutListingSection;
